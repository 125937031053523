import React from 'react';
import octusMapa from './images/octusMapa.png';
import octusContacto from './images/octusContacto.png';
import octusContactoQR from './images/octusContactoQR.png';
import octusContactoWpp from './images/octusContactoWpp.png';

const Contacto: React.FC<any> = () => {
    return (
    <>
    <section className='cont-contact-c1' id='contacto'>
        <figure className='cont-contact-c1-mapa'>
            <img src={octusMapa} alt="Mapa de Octus y sus provincias"  className='cont-contact-c1-mapa__img'/>
            <article className='cont-contact-c1-info'>
                <p className='cont-contact-c1-info__text'>
                    Estamos listos para fortalecer la colaboración en salud,
                    mejorando la eficiencia y la comunicación en todo el ecosistema
                    de atención médica en Argentina.
                </p>
            </article>
        </figure>
    </section>
    <footer className='cont-contact-c2'>
        <section className='cont-contact-c2-footer'>
            <article className='cont-contact-c2-footer-box1'>
                <p className='cont-contact-c2-footer-box1__title'>¡CONTACTANOS HOY MISMO!</p>
            </article>
            <article className='cont-contact-c2-footer-box2'>
                <p className='cont-contact-c2-footer-box1__text'>Para conocer más sobre cómo OCTUS puede transformar tu manera de trabajar.</p>
                <section className='cont-contact-c2-footer-box2--flex'>
                    <section className='cont-contact-c2-footer-box2-logos'>
                        <figure className='cont-contact-c2-footer-box2-logo'>
                            <img src={octusContacto} alt="Logo Octus"  className='cont-contact-c2-footer-box2-logo__img'/>
                        </figure>
                        <figure className='cont-contact-c2-footer-box2-logo'>
                            <img src={octusContactoQR} alt="QR WhatsApp de Octus"  className='cont-contact-c2-footer-box2-logo__img'/>
                        </figure>
                    </section>
                    <article className='cont-contact-c2-footer-box2-info'>
                        <a className='cont-contact-c2-footer-box2-info__link' href='https://octus.com.ar/' target='_blank'>www.octus.com.ar</a>
                        <a className='cont-contact-c2-footer-box2-info__wpp'
                            target="_blank"
                            href="https://api.whatsapp.com/send?phone=3564389486&text=Hola%21%20Quisiera%20recibir%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Octus.">
                            <img src={octusContactoWpp} alt="whatsApp de Octus" className='cont-contact-c2-footer-box2-info__wpp__img'/>
                            +54 (3564) 38-9486
                        </a>
                        <a className='cont-contact-c2-footer-box2-info__email'
                        href='mailto:octussoftware@gmail.com?subject=Consulta&body=Hola! Quisiera recibir más información sobre Octus."'>octussoftware@gmail.com</a>
                    </article>
                </section>
            </article>
        </section>
    </footer>
    </>
    )
}

export default Contacto